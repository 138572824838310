<!--
 * @Author: yy
 * @Date: 2021-09-29 16:22:48
 * @LastEditTime: 2021-12-21 13:01:04
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\views\wrongQuestion\index.vue
-->
<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="false">
        <span>错题中心</span>
        <v-icon class="screenBtn" @click="openMask">$revise</v-icon>
        <div class="revisedBtn" @click="toName('WrongQuestionRevised')">已订正错题</div>
      </public-header>
    </el-header>
    <el-main>
      <div class="cardBorder">
        <v-card class="card">
          <loadMore
            ref="more"
            @query="queryList"
            :list.sync="list"
            :total.sync="total"
            defaultHeight="calc(100vh - 122px)"
            path="wrongQuestion"
          >
            <div class="error-num" v-if="total > 0">共有{{total}}道试题</div>
            <template v-if="list">
              <question-item
                v-for="(item,index) in list"
                :key="item.questionId"
                :info="item"
                @clickQuestion="clickQuestion(item,index)"
              ></question-item>
            </template>
          </loadMore>
        </v-card>
      </div>
      <div class="testBtn" @click="beginTest" v-if="list&&list.length > 0">错题重测</div>
      <reviseMask :show="showReviseMask" @closeMask="closeMask" :label.sync="label" />
      <begin-error-test-msg-box :visible.sync="errorTestVisible" @submit="goBeginTesting"></begin-error-test-msg-box>
      <question-info-dialog
        :visible.sync="questionInfoVisible"
        :questionInfo="currentQuestion"
        @reasonChange="reasonChange"
      ></question-info-dialog>
    </el-main>
  </el-container>
</template>
<script>
import reviseMask from '@/components/wrongQuestion/reviseMask'
import questionItem from '../../components/wrongQuestion/questionItem'
import beginErrorTestMsgBox from '../../components/dialogs/beginErrorTestMsgBox'
import questionInfoDialog from '../../components/dialogs/questionInfoDialog'
import loadMore from '@/components/loadMore/loadMore'

export default {
  components: {
    reviseMask,
    questionItem,
    beginErrorTestMsgBox,
    questionInfoDialog,
    loadMore,
  },
  data: () => ({
    showReviseMask: false,
    errorReasonId: null,
    label: [],
    list: [],
    total: 0,
    errorTestVisible: false,
    questionInfoVisible: false,
    currentQuestion: {},
    currentQuestionIndex: 0,
  }),
  methods: {
    toName(name) {
      this.$router.push({
        name: name,
      })
    },
    queryList(pageNum, pageSize) {
      this.$service
        .getErrorQuestionList({
          pageNum,
          pageSize,
          errorQuestionStatus: 1, // 1 未完成订正错题 2 已经完成订正错题
          errorReasonId: this.errorReasonId,
          label: this.label,
        })
        .then((res) => {
          this.total = res.totalCount
          if (res.errorQuestions && res.errorQuestions.length) {
            let arr = res.errorQuestions.map((item) => {
              return {
                ...item,
                errorReason: this.$getTag(item.errorReasonId),
              }
            })
            this.$refs.more.complete(arr, res.totalCount)
          } else {
            this.$refs.more.complete()
          }
        })
    },
    openMask() {
      this.showReviseMask = true
    },
    closeMask(errorReasonId) {
      this.showReviseMask = false
      this.errorReasonId = errorReasonId
      setTimeout(() => {
        this.$refs.more.reReload()
      }, 50)
    },
    beginTest() {
      this.errorTestVisible = true
    },
    goBeginTesting() {
      this.$router.push({
        name: 'StartErrorTesting',
        query: {
          type: 'error',
        },
      })
    },
    async clickQuestion(val, index) {
      this.currentQuestionIndex = index
      const questionInfo = await this.getQuestionInfo(val.questionId)
      const errorInfo = await this.getQuestionErrorInfo(val.questionId)
      let result = {
        ...val,
        ...questionInfo,
        ...errorInfo,
      }
      // 选择题选项显示对错
      if (result.type == 1) {
        if (val.answer) {
          result.myAnswer = Number(val.answer)
          result.responseStatus = 2
          result.errorOption = result.myAnswer
        } else {
          result.responseStatus = 3
        }
        result.successOption = Number(result.answer)
      }
      this.currentQuestion = result
      this.questionInfoVisible = true
    },
    // 获取题目详情
    async getQuestionInfo(questionId) {
      let params = {
        questionID: questionId,
      }
      const itemQuestion = await this.$service.getQuestionInfo(params)
      let result = {
        ...this.$formattedData(itemQuestion),
      }
      return Promise.resolve(result)
    },
    // 获取错因信息
    async getQuestionErrorInfo(questionId) {
      let params = {
        questionId: questionId,
      }
      const res = await this.$service.getQuestionErrorInfo(params)
      res.errorReasonId = res.errorReasonId ? res.errorReasonId : []
      res.errorReason = this.$getTag(res.errorReasonId)
      return Promise.resolve(res)
    },
    // 修改错因后刷新数据
    async reasonChange() {
      const errorInfo = await this.getQuestionErrorInfo(
        this.currentQuestion.questionId
      )
      this.currentQuestion = {
        ...this.currentQuestion,
        ...errorInfo,
      }
      this.$set(this.list, this.currentQuestionIndex, this.currentQuestion)
    },
  },
}
</script>
<style lang="scss" scoped>
.revisedBtn {
  float: right;
  width: 120px;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin: 12px 40px 0 0;
  color: #666;
  font-family: PingFangSC-Regular, PingFang SC;
}
.screenBtn {
  float: right;
  width: 40px;
  height: 40px;
  margin: 12px 0;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.cardBorder {
  height: calc(100vh - 96px);
  padding: 12px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  border: 2px solid #ffffff;
  .card {
    height: calc(100vh - 122px);
    padding: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    border-radius: 24px;
    .error-num {
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      color: #999;
      text-indent: 28px;
      border-bottom: 1px solid #eee;
    }
    & > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
      border-radius: 0;
    }
  }
}
.testBtn {
  width: 90px;
  height: 90px;
  background: #ffe900;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.15);
  border-radius: 37.5%;
  position: fixed;
  bottom: 60px;
  right: 110px;
  padding: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 25px;
  text-align: center;
  font-size: 19px;
}
</style>
