<!--
 * @Author: wyq
 * @Date: 2021-08-03 16:09:10
 * @LastEditTime: 2021-10-03 15:04:41
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\dialogs\beginErrorTestMsgBox.vue
-->
<template>
  <msg-box-template
    class="begin-error-test-dialog"
    :visible.sync="dialogVisible"
    title="错题重测"
    :imgUrl="require('@/assets/images/dialog/start.png')"
  >
    错题重测将会为你抽选5道高频错题，快来试试挑战他们吧！
    
    <span slot="tip">新加入错题需要等待三天后才可以重新测试</span>
    <template slot="btnGroup">
      <v-btn
        color="primary"
        depressed
        block
        x-large
        class="rounded-sm"
        @click="submit"
        :loading="submitLoadding"
      >开始测试</v-btn>
    </template>
  </msg-box-template>
</template>
<script>
import msgBoxTemplate from './msgBoxTemplate'
import mixin from '@/utils/dialogMixin'
export default {
  components: {
    msgBoxTemplate,
  },
  mixins: [mixin],
}
</script>
<style lang="scss" scoped>
.begin-error-test-dialog {
}
</style>